import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Advantages } from '../Advantages';
import { License } from '../License';
import { About } from '../About';
import { Header } from '../Header';
import { Main } from '../Main';
import { Partnership } from '../Partnership';
import { Footer } from '../Footer';
import VideoComponent from '../VideoComponent/VideoComponent';
import './App.scss';

export const App = () => {
	const [isVideoOpen, setVideoOpen] = useState(false);

	const handleVideoOpen = () => {
		!isVideoOpen
			? document.querySelector('body').classList.add('noscroll')
			: document.querySelector('body').classList.remove('noscroll');
		setVideoOpen(!isVideoOpen);
	};

	let prevScrollpos = window.pageYOffset;
	window.onscroll = function () {
		let currentScrollPos = window.pageYOffset;
		let header = document.querySelector('.header');
		if (prevScrollpos > currentScrollPos) {
			header.style.top = '0';
		} else {
			header.style.top = '-150px';
		}
		prevScrollpos = currentScrollPos;
	};

	return (
		<>
			{isVideoOpen && <VideoComponent openVideo={handleVideoOpen} />}
			<BrowserRouter>
				<div className='container'>
					<Header />
					<Main openVideo={handleVideoOpen} />

					<Advantages />
					<License />
					<About />
					<Partnership />
					<Footer />
				</div>
				<p className='info'>© 2021 Socket Car. All rights reserved</p>
			</BrowserRouter>
		</>
	);
};
