import React,{useEffect} from 'react';
import './Partnership.scss';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import puzzle from '../../images/mobile/puzzle.png';
import puzzleTablet from '../../images/tablet/puzzle-tablet.png';
import puzzleDesktop from '../../images/desktop/puzzle-desktop.jpg';
import { useTranslation } from 'react-i18next';

export const Partnership = () => {
	const { t } = useTranslation();

	useEffect(() => {
		let url = window.location.href.split('/');
		let target = url[url.length - 1];
		let element = document.getElementById(target);
		element && element.scrollIntoView({ behavior: 'smooth' });
	}, []);

	const addPath = (e, elem, path) => {
		let hero = document.getElementById(elem);
		hero && hero.scrollIntoView({ behavior: 'smooth' });
		window.history.pushState('', '', path);
	};

	return (
		<section className='partnership' id='Partnership'>
			<div className='partnership__img-box'>
				<img className='partnership__img' src={puzzle} alt='puzzle' />
				<img className='partnership__img-tablet' src={puzzleTablet} alt='puzzle'/>
				<img className='partnership__img-desktop' src={puzzleDesktop} alt='puzzle' />
			</div>
			<div className='partnership__content-box'>
				<h2 className='partnership__title'>{t('partnership.title')}</h2>
				<p className='partnership__text'>{t('partnership.offer')}</p>
				<Button onBtnClick={(e) => addPath(e, 'Contacts', '/contacts')}>{t('button.partner')}</Button>
			</div>
		</section>
	);
};

Partnership.propTypes = {
	openModal: PropTypes.func,
};

Partnership.defaultProps = {
	openModal: () => {},
};
