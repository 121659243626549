import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Header.scss';
import logo from '../../images/mobile/logo.svg';
import { Menu } from '../Menu';

export const Header = ({ addPath }) => {
	const [menuActive, setMenuActive] = useState(false);

	useEffect(() => {
		let body = document.querySelector('body');
		menuActive
			? body.classList.add('noscroll')
			: body.classList.remove('noscroll');
	}, [menuActive]);

	const menuOnClick = () => {
		setMenuActive(!menuActive);
	};

	return (
		<header className='header'>
			<div className='header__logo-box'>
				<img src={logo} alt='logo' />
			</div>
			<nav>
				<div
					className={menuActive ? 'burger-active' : 'burger'}
					onClick={menuOnClick}
				>
					<span></span>
				</div>
				<Menu
					openModal={addPath}
					active={menuActive}
					setActive={setMenuActive}
				/>
			</nav>
		</header>
	);
};

Header.propTypes = {
	openModal: PropTypes.func,
};

Header.defaultProps = {
	openModal: () => {},
};
