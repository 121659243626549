import React from 'react';
import './About.scss';
import medal from '../../images/mobile/medal.svg';
import mechanic from '../../images/mobile/mechanic.png';
import mechanicTablet from '../../images/tablet/mechanic-tablet.png';
import mechanicDesktop from '../../images/desktop/mechanic-desktop.jpg';

import { useTranslation } from 'react-i18next';

export const About = () => {
	const { t } = useTranslation();

	return (
		<section className='about' id='AboutUs'>
			<div className='about__img-box'>
				<img className='about__img' src={mechanic} alt='mechanic' />
				<img
					className='about__img-tablet'
					src={mechanicTablet}
					alt='mechanic'
				/>
				<img
					className='about__img-desktop'
					src={mechanicDesktop}
					alt='mechanic'
				/>
			</div>
			<div className='about__content-box'>
				<h2 className='about__title'>{t('aboutUs.title')}</h2>
				<p className='about__text-center'>{t('aboutUs.description')}</p>
				<p className='about__text-center'>{t('aboutUs.conclusion')}</p>
				<div className='about__block'>
					<img src={medal} alt='medal' />
					<p className='about__text'>
						{t('aboutUs.partner.top')}
						<br />
						{t('aboutUs.partner.bot')}
					</p>
				</div>
			</div>
		</section>
	);
};
