import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

export const Button = ({ background, children, onBtnClick }) => {
	return (
		<button
			onClick={onBtnClick}
			className={background ? `btn btn-${background}` : 'btn'}
		>
			{children}
		</button>
	);
};

Button.propTypes = {
	background: PropTypes.string,
	children: PropTypes.string.isRequired,
	onBtnClick: PropTypes.func,
};

Button.defaultProps = {
	background: '',
	onBtnClick: () => {},
};
