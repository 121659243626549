import { useState, useEffect } from 'react';
import logo from '../../images/mobile/logo.svg';
import './Menu.scss';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import flagUs from '../../images/desktop/flag-us.svg';
import flagRu from '../../images/desktop/flag-ru.svg';
import flagUa from '../../images/desktop/flag-ua.svg';
import { SocialBlock } from '../SocialBlock/SocialBlock';
import { useTranslation } from 'react-i18next';

export const Menu = ({ active, setActive }) => {
	const [flags, setFlags] = useState([
		{ id: 'ua', img: flagUa },
		{ id: 'en', img: flagUs },
		{ id: 'ru', img: flagRu },
	]);

	const [isLangShow, setLangShow] = useState(false);

	const { t, i18n } = useTranslation();

	useEffect(() => {
		let url = window.location.href.split('/');
		let target = url[url.length - 1];
		let element = document.getElementById(target);
		element && element.scrollIntoView({ behavior: 'smooth' });
	}, []);

	const addPath = (e, elem, path) => {
		let hero = document.getElementById(elem);
		setActive(false);
		hero && hero.scrollIntoView({ behavior: 'smooth' });
		window.history.pushState('', '', path);
	};

	const changeLanguage = (language) => i18n.changeLanguage(language);

	const handleMenuClick = (langId) => {
		changeLanguage(langId);

		if (langId === 'en') {
			document.querySelector('.b24-form-header-title').innerText = 'Contact us';
			document.querySelector('.b24-form-header-description').innerText =
				'Get advice from our managers';
			document.querySelectorAll('.b24-form-control-label')[0].innerText =
				'Name';
			document.querySelectorAll('.b24-form-control-label')[1].innerText =
				'Surname';
			document.querySelectorAll('.b24-form-control-label')[2].innerText =
				'Telephone';
			document.querySelectorAll('.b24-form-control-label')[3].innerText =
				'E-mail';
			document.querySelectorAll('.b24-form-control-label')[4].innerText =
				'Car make and model, year of manufacture';
			document.querySelectorAll('.b24-form-btn')[2].innerText = 'Send';
			[...document.querySelectorAll('.b24-form-control-alert-message')].map(
				(i) => (i.innerText = 'This field is required')
			);
		}

		if (langId === 'ru') {
			document.querySelector('.b24-form-header-title').innerText =
				'Свяжитесь с нами';
			document.querySelector('.b24-form-header-description').innerText =
				'Получите консультацию у наших менеджеров';
			document.querySelectorAll('.b24-form-control-label')[0].innerText = 'Имя';
			document.querySelectorAll('.b24-form-control-label')[1].innerText =
				'Фамилия';
			document.querySelectorAll('.b24-form-control-label')[2].innerText =
				'Телефон';
			document.querySelectorAll('.b24-form-control-label')[3].innerText =
				'E-mail';
			document.querySelectorAll('.b24-form-control-label')[4].innerText =
				'Марка и модель авто, год выпуска';
			document.querySelectorAll('.b24-form-btn')[2].innerText = 'Отправить';
			[...document.querySelectorAll('.b24-form-control-alert-message')].map(
				(i) => (i.innerText = 'Поле обязательное для заполнения')
			);
		}

		if (langId === 'ua') {
			document.querySelector('.b24-form-header-title').innerText =
				"Зв'яжіться з нами";
			document.querySelector('.b24-form-header-description').innerText =
				'Отримайте консультацію у наших менеджерів';
			document.querySelectorAll('.b24-form-control-label')[0].innerText =
				"Ім'я";
			document.querySelectorAll('.b24-form-control-label')[1].innerText =
				'Прізвище';
			document.querySelectorAll('.b24-form-control-label')[2].innerText =
				'Телефон';
			document.querySelectorAll('.b24-form-control-label')[3].innerText =
				'E-mail';
			document.querySelectorAll('.b24-form-control-label')[4].innerText =
				'Марка та модель авто, рік випуску';
			document.querySelectorAll('.b24-form-btn')[2].innerText = 'Надіслати';
			[...document.querySelectorAll('.b24-form-control-alert-message')].map(
				(alert) => (alert.innerText = "Поле обов'язкове для заповнення")
			);
		}

		const sortedFlags = [
			...flags.filter((obj) => obj.id === langId),
			...flags.filter((obj) => obj.id !== langId),
		];

		setFlags(sortedFlags);
	};

	return (
		<div className={active ? 'menu active' : 'menu'}>
			<div className='blur' onClick={() => setActive(false)} />
			<div className='menu__content'>
				<div className='menu__title' onClick={(e) => addPath(e, 'main', '/')}>
					<img src={logo} alt='logo' />
				</div>
				<div className='menu__nav-box'>
					<span
						className='menu__nav-item'
						onClick={(e) => addPath(e, 'HowItWork', '/info')}
					>
						{t('menu.howItWork')}
					</span>
					<span
						className='menu__nav-item'
						onClick={(e) => addPath(e, 'AboutUs', '/about')}
					>
						{t('menu.aboutUs')}
					</span>
					<span
						className='menu__nav-item'
						onClick={(e) => addPath(e, 'Partnership', '/partnership')}
					>
						{t('menu.partnership')}
					</span>
					<span
						className='menu__nav-item'
						onClick={(e) => addPath(e, 'Contacts', '/contacts')}
					>
						{t('menu.contacts')}
					</span>
				</div>
				<div className='menu__btn-box'>
					<Button onBtnClick={(e) => addPath(e, 'Contacts', '/contacts')}>
						{t('button.buyNow')}
					</Button>
					<div
						className={!isLangShow ? 'menu__lang-box' : 'menu__lang-box show'}
					>
						<div
							className='menu__lang-wrapper'
							onClick={() => setLangShow(!isLangShow)}
						>
							{flags.map((obj) => (
								<button
									key={obj.id}
									className='menu__flag'
									onClick={() => handleMenuClick(obj.id)}
								>
									<img src={obj.img} alt='flag' />
								</button>
							))}
						</div>
					</div>
				</div>
				<div className='menu__info-box'>
					<div className='menu__info'>
						<SocialBlock />
						<p className='info'>© 2021 Socket Car. All rights reserved</p>
					</div>
				</div>
			</div>
		</div>
	);
};

Menu.propTypes = {
	openModal: PropTypes.func,
};

Menu.defaultProps = {
	openModal: () => {},
};
