import React from 'react';
import './SocialBlock.scss';
import fb from '../../images/mobile/fb-icon.svg';
import youtube from '../../images/mobile/youtube-icon.svg';
import telegram from '../../images/mobile/telegram-icon.svg';
import twitter from '../../images/mobile/twitter-icon.svg';
import insta from '../../images/mobile/instagram-icon.svg';

export const SocialBlock = () => {
  const icons = [
    { link: 'https://fb.me/Socketcar777', icon: fb, altText: 'facebook', number: 'icon1', network: 'fb' },
    { link: 'https://www.youtube.com/channel/UCVKwqZKuId87r8oE1wQqL3w/about', icon: youtube, altText: 'youtube', number: 'icon2', network: 'youtube' },
    { link: 'https://instagram.com/socketcar?igshid=axedbw4ahd4w', icon: insta, altText: 'instagram', number: 'icon3', network: 'insta' },
    { link: 'https://t.me/socketcar', icon: telegram, altText: 'telegram', number: 'icon4', network: 'telegram'  },
    { link: 'https://twitter.com/socket_car?s=09', icon: twitter, altText: 'twitter', number: 'icon5', network: 'twitter' }
  ]
  return (
    <div className='social'>
      {icons.map((item, index) =>
        <a key={index} className='social__link' href={item.link}>
          <span  className={`social__circle social__circle--${item.network}`}>
            <img src={item.icon} alt={item.altText} />
          </span>
        </a>
      )}
    </div>
  )
}
