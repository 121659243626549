import React from 'react';
import './Form.scss';

export const Form = () => {
	return (
		<div className='form-wrapper'>
			<script data-b24-form='inline/4/e33w4w' data-skip-moving='true'>
				{(function (w, d, u) {
					var s = d.createElement('script');
					s.async = true;
					s.src = u + '?' + ((Date.now() / 180000) | 0);
					var h = d.getElementsByTagName('script')[0];
					h.parentNode.insertBefore(s, h);
				})(
					window,
					document,
					'https://cdn.bitrix24.ua/b16909045/crm/form/loader_4.js'
				)}
			</script>
		</div>
	);
};
