import React from 'react';
import './Footer.scss';
import logowhite from '../../images/mobile/logowhite.svg';
import mail from '../../images/mobile/mail.svg';
import { Form } from '../Form';
import { SocialBlock } from '../SocialBlock/SocialBlock';

import { useTranslation } from 'react-i18next';

export const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className='footer' id='Contacts'>
			<div className='footer__top-box'>
				<Form />
			</div>
			<div className='footer__bot-box'>
				<div>
					<img className='footer__bot-logo' src={logowhite} alt='logo' />
				</div>
				<p className='footer__bot-text'>{t('contactUs.description')}</p>
				<div className='footer__fone-box'>
					<a className='footer__fone' href='tel:+380662171771'>
						+38 066 217 17 71
					</a>
					<a className='footer__fone' href='tel:+380732171771'>
						+38 073 217 17 71
					</a>
					<a className='footer__fone' href='tel:+380962171771'>
						+38 096 217 17 71
					</a>
				</div>
				<div className='footer__bot-mail'>
					<img src={mail} alt='mail' />
					<p className='footer__bot-mail-text'>contact@socketcar.com</p>
				</div>
				<SocialBlock />
			</div>
		</footer>
	);
};
