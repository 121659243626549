import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const VideoComponent = ({ openVideo }) => {
	return (
		<div className='vid' onClick={openVideo}>
			<iframe
				width='560'
				height='315'
				src='https://www.youtube.com/embed/gb1VZ7Mjur4'
				title='YouTube video player'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			/>
		</div>
	);
};

VideoComponent.propTypes = {
	openVideo: PropTypes.func,
};

VideoComponent.defaultProps = {
	openVideo: () => {},
};

export default VideoComponent;
