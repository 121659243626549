import React, { useState } from 'react';
import './Advantages.scss';
import key from '../../images/mobile/key.svg';
import connector from '../../images/mobile/Connector.svg';
import shild from '../../images/mobile/shild.svg';
import circle from '../../images/mobile/circle.svg';
import cable from '../../images/mobile/cable.svg';
import tester from '../../images/mobile/tester.svg';
import wheel from '../../images/mobile/wheel.svg';
import doc from '../../images/mobile/document.svg';
import wheelDesctop from '../../images/desktop/wheel-desctop.svg';
import sertificate1 from '../../images/desktop/sertificate.jpg';
import sertificate2 from '../../images/desktop/sertificate2.jpg';
import component from '../../images/desktop/component-icon.svg';
import service from '../../images/desktop/service-icon.svg';

import { useTranslation } from 'react-i18next';

export const Advantages = () => {
	const [isSertificateOpen, setSertificateOpen] = useState(false);

	const { t } = useTranslation();

	const content = [
		{
			icon: key,
			text: t('advantages.key'),
			number: '1',
		},
		{
			icon: connector,
			text: t('advantages.connector'),
			number: '2',
		},
		{
			icon: shild,
			text: t('advantages.shild'),
			number: '3',
		},
		{
			icon: circle,
			text: t('advantages.circle'),
			number: '4',
		},
		{
			icon: cable,
			text: t('advantages.cable'),
			number: '5',
		},
		{
			icon: tester,
			text: t('advantages.tester'),
			number: '6',
		},
		{
			icon: wheel,
			text: t('advantages.wheel'),
			number: '7',
		},
	];

	return (
		<section className='advantages'>
			<h2 className='advantages__title'>{t('advantages.title')}</h2>
			<div className='advantages__main-box'>
				{content.map((item, index) => (
					<div
						key={index}
						className={
							item.number
								? `advantages__content-box advantages__content-box${item.number}`
								: 'advantages__content-box'
						}
					>
						<div className='advantages__img-box'>
							<img src={item.icon} alt='icon' />
						</div>
						<p className='advantages__text'>{item.text}</p>
					</div>
				))}
				<div className='advantages__content-box'>
					<div>
						<img src={doc} alt='icon' />
					</div>
					<p className='advantages__text'>
						{t('advantages.certificate.text')}
						<span onClick={() => setSertificateOpen(!isSertificateOpen)}>
							{t('advantages.certificate.link')}
						</span>
					</p>
				</div>
				<div className='advantages__wheel'>
					<img src={wheelDesctop} alt='wheel' />
				</div>
			</div>
			{isSertificateOpen && (
				<div className='advantages__sertificate'>
					<div>
						<img src={sertificate2} alt='sertificate' />
					</div>
					<div>
						<img src={sertificate1} alt='sertificate' />
					</div>
				</div>
			)}
			<div className='advantages__row' />
			<div className='advantages__info-wrapper'>
				<div className='advantages__info-box'>
					<div className='advantages__info-img'>
						<img src={component} alt='component' />
					</div>
					<h3 className='advantages__info-title'>{t('components.title')}</h3>
					<p className='advantages__info-text bold'>
						{t('components.subTitle')}
					</p>
					<ul>
						<li className='advantages__info-text'>
							{t('components.multiPin')}
						</li>
						<li className='advantages__info-text'>
							{t('components.connector')}
						</li>
						<li className='advantages__info-text'>{t('components.wires')}</li>
						<li className='advantages__info-text'>{t('components.manual')}</li>
					</ul>
				</div>
				<div className='advantages__info-box'>
					<div className='advantages__info-img'>
						<img src={service} alt='service' />
					</div>
					<h3 className='advantages__info-title'>{t('service.title')}</h3>
					<p className='advantages__info-text'>{t('service.system')}</p>
					<p className='advantages__info-text'>{t('service.description')}</p>
				</div>
			</div>
		</section>
	);
};
