import React from 'react';
import PropTypes from 'prop-types';
import './Main.scss';
import { Button } from '../Button';
import alfa from '../../images/mobile/AcLogo.png';
import oranta from '../../images/mobile/orantaLogo.png';
import ingo from '../../images/mobile/ingoLogo.png';
import tac from '../../images/mobile/TasLogo.png';
import kraina from '../../images/mobile/krainaLogo.png';
import shema from '../../images/mobile/Shema.svg';
import shemaTabl from '../../images/tablet/Shema-table.svg';
import shemaDesk from '../../images/desktop/Shema-desktop.svg';
import shemaUA from '../../images/mobile/ShemaUA.svg';
import shemaTablUA from '../../images/tablet/Shema-tablUA.svg';
import shemaDeskUA from '../../images/desktop/Shema-desktopUA.svg';
import shemaRU from '../../images/mobile/ShemaRU.svg';
import shemaTablRU from '../../images/tablet/Shema-tablRU.svg';
import shemaDeskRU from '../../images/desktop/Shema-desktopRU.svg';

import { useTranslation } from 'react-i18next';

export const Main = ({ openVideo, openModal }) => {
	const { t, i18n } = useTranslation();

	const addPath = (e, elem, path) => {
		let hero = document.getElementById(elem);
		hero && hero.scrollIntoView({ behavior: 'smooth' });
		window.history.pushState('', '', path);
	};

	return (
		<section className='main' id='main'>
			<div className='container'>
				<div className='main__wrapper'>
					<div className='main__content-box'>
						<h1 className='main__title'>
							{t('title.safety')}
							<br /> {t('title.reliability')}
							<br /> {t('title.comfort')}
						</h1>
						<p className='main__text main__text-custom'>{t('sub-title')}</p>
						<div className='main__video-box'>
							<Button
								onBtnClick={(e) => addPath(e, 'HowItWork', '/info')}
								background='transparent'
							>
								{t('button.discover')}
							</Button>
							<span className='main__video' onClick={openVideo}>
								{t('button.watchVideo')}
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										className='svg-fill'
										d='M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z'
										fill='#00C6C2'
									/>
									<path
										className='svg-fill'
										d='M16.8607 11.6981L9.92085 6.65902C9.86519 6.61822 9.7993 6.59367 9.73051 6.58812C9.66172 6.58257 9.59274 6.59623 9.53126 6.62758C9.46978 6.65893 9.41821 6.70673 9.3823 6.76567C9.34639 6.8246 9.32755 6.89235 9.32789 6.96136V17.0395C9.32789 17.3465 9.67476 17.52 9.92085 17.3419L16.8607 12.3028C16.9086 12.2683 16.9476 12.2228 16.9745 12.1703C17.0015 12.1177 17.0155 12.0595 17.0155 12.0004C17.0155 11.9414 17.0015 11.8832 16.9745 11.8306C16.9476 11.778 16.9086 11.7326 16.8607 11.6981ZM10.8232 14.8387V9.16215L14.7302 12.0004L10.8232 14.8387Z'
										fill='#00C6C2'
									/>
								</svg>
							</span>
						</div>
						<div className='main__img-box'>
							<img
								className='main__img'
								src={
									i18n.language === 'en'
										? shema
										: i18n.language === 'ua'
										? shemaUA
										: shemaRU
								}
								alt='shema'
							/>
						</div>
						<h2 className='main__sub-title' id='HowItWork'>
							{t('howItWorks')}
						</h2>
						<p className='main__text-line'>
							{t('howItWorksDescription.device')}
						</p>
						<p className='main__text-line'>
							{t('howItWorksDescription.system')}
						</p>
						<p className='main__text'>
							{t('howItWorksDescription.aesthetics')}
						</p>
						<Button onBtnClick={(e) => addPath(e, 'Contacts', '/contacts')}>
							{t('button.protect')}
						</Button>
					</div>
					<div className='main__img-box-tablet'>
						<img
							className='main__img'
							src={
								i18n.language === 'en'
									? shemaTabl
									: i18n.language === 'ua'
									? shemaTablUA
									: shemaTablRU
							}
							alt='shema'
						/>
					</div>
					<div className='main__img-box-desktop'>
						<img
							className='main__img'
							src={
								i18n.language === 'en'
									? shemaDesk
									: i18n.language === 'ua'
									? shemaDeskUA
									: shemaDeskRU
							}
							alt='shema'
						/>
					</div>
				</div>
				<h2 className='sub-title'>{t('ourPartners')}</h2>
				<div className='main__advertising-box'>
					<div className='main__advertising'>
						<img
							className='main__advertising-item1'
							src={oranta}
							alt='oranta'
						/>
						<img className='main__advertising-item2' src={tac} alt='tac' />
						<img
							className='main__advertising-item3'
							src={kraina}
							alt='kraina'
						/>
						<img className='main__advertising-item4' src={ingo} alt='ingo' />
						<img className='main__advertising-item5' src={alfa} alt='alfa' />
					</div>
				</div>
			</div>
		</section>
	);
};

Main.propTypes = {
	openVideo: PropTypes.func,
	openModal: PropTypes.func,
};

Main.defaultProps = {
	openVideo: () => {},
	openModal: () => {},
};
