import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './License.scss';
import './Card.scss';
import minicar from '../../images/mobile/mini-car.png';
import smallcar from '../../images/mobile/small-car.png';
import mediumcar from '../../images/mobile/medium-car.png';
import largerCars from '../../images/mobile/largerCars.png';
import executiveCars from '../../images/mobile/executiveCars.png';
import luxuryCars from '../../images/mobile/luxuryCars.png';
import sportUtility from '../../images/mobile/sportUtility.png';
import multiPurpose from '../../images/mobile/multiPurpose.png';
import coupeCars from '../../images/mobile/cuopeCars.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Pagination]);

export const License = () => {
	const { t } = useTranslation();
	const [conversionMoney, setConversionMoney] = useState();

	useEffect(() => {
		axios
			.get(' https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5')
			.then((response) => {
				setConversionMoney(
					response.data.filter((i) => i.ccy === 'USD')[0].sale
				);
			});
	}, []);

	return (
		<section className='license'>
			<h2 className='license__title'>{t('license')}</h2>
			<div className='license__carousel'>
				<Swiper
					slidesPerView={'auto'}
					spaceBetween={22}
					pagination={{
						clickable: true,
					}}
				>
					<SwiperSlide>
						<div className='card'>
							<div className='card__img-box'>
								<img src={minicar} alt='car' />
							</div>
							<div className='card__title-color'>
								{t('miniCars')}
								<span className='card__title'>a-class</span>
							</div>
							<p className='card__text'>
								Kia Picanto, Smart Fortwo, Hyundai i10, Citroen C1, Chevrolet
								Spark, Daewoo Matiz
							</p>
							<div className='card__bot'>
								<div className='card__bot-part'>
									<p className='card__bot-text'>8pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 300) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 350) / 100) * 100}
									</p>
								</div>
								<div className='card__bot-part'>
									<p className='card__bot-text'>16pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 450) / 100) * 100} -
										&#8372;
										{Math.ceil(conversionMoney * 500)}
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='card'>
							<div className='card__img-box'>
								<img src={smallcar} alt='car' />
							</div>
							<div className='card__title-color'>
								{t('smallCars')}
								<span className='card__title'>b-class</span>
							</div>
							<p className='card__text'>
								Audi A1, Chevrolet Aveo, Citroen C3, Renault Logan, Ford Fiesta,
								Kia Rio, Hyundai Accent
							</p>
							<div className='card__bot'>
								<div className='card__bot-part'>
									<p className='card__bot-text'>8pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 350) / 100) * 100} -
										&#8372;
										{Math.ceil(conversionMoney * 500)}
									</p>
								</div>
								<div className='card__bot-part'>
									<p className='card__bot-text'>16pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 550) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 800) / 100) * 100}
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='card'>
							<div className='card__img-box'>
								<img src={mediumcar} alt='car' />
							</div>
							<div className='card__title-color'>
								{t('mediumCars')}
								<span className='card__title'>c-class</span>
							</div>
							<p className='card__text'>
								Volkswagen Golf, Honda Civic, Skoda Octavia, Ford Focus, Audi A3
							</p>
							<div className='card__bot'>
								<div className='card__bot-part'>
									<p className='card__bot-text'>8pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 400) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 550) / 100) * 100}
									</p>
								</div>
								<div className='card__bot-part'>
									<p className='card__bot-text'>16pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 600) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 800) / 100) * 100}
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='card'>
							<div className='card__img-box'>
								<img src={largerCars} alt='car' />
							</div>
							<div className='card__title-color'>
								{t('largerCars')}
								<span className='card__title'>d-class</span>
							</div>
							<p className='card__text'>
								Toyota Camry, Honda Accord, Skoda Superb, Volkswagen Passat,
								Audi A4, Chrvrolet Malibu
							</p>
							<div className='card__bot'>
								<div className='card__bot-part'>
									<p className='card__bot-text'>8pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 550) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 850) / 100) * 100}
									</p>
								</div>
								<div className='card__bot-part'>
									<p className='card__bot-text'>16pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 900) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 1200) / 100) * 100}
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='card'>
							<div className='card__img-box'>
								<img src={executiveCars} alt='car' />
							</div>
							<div className='card__title-color'>
								{t('executiveCars')}
								<span className='card__title'>e-class</span>
							</div>
							<p className='card__text'>
								Audi A6, Mercedes-Benz E-class, BMW 5-series, Hyundai Grandeur,
								Honda Legend
							</p>
							<div className='card__bot'>
								<div className='card__bot-part'>
									<p className='card__bot-text'>8pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 700) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 1200) / 100) * 100}
									</p>
								</div>
								<div className='card__bot-part'>
									<p className='card__bot-text'>16pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 1100) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 1500) / 100) * 100}
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='card'>
							<div className='card__img-box'>
								<img src={luxuryCars} alt='car' />
							</div>
							<div className='card__title-color'>
								{t('luxuryCars')}
								<span className='card__title'>f-class</span>
							</div>
							<p className='card__text'>
								Audi A8, BMW 7-series, Mercedes-Benz S-class, Volkswagen
								Phaeton, Bentley Flying Spur
							</p>
							<div className='card__bot'>
								<div className='card__bot-part'>
									<p className='card__bot-text'>8pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 700) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 1200) / 100) * 100}
									</p>
								</div>
								<div className='card__bot-part'>
									<p className='card__bot-text'>16pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 1300) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 1800) / 100) * 100}
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='card'>
							<div className='card__img-box'>
								<img src={sportUtility} alt='car' />
							</div>
							<div className='card__title-color'>
								{t('sportsUtility')}
								<span className='card__title'>j-class</span>
							</div>
							<p className='card__text'>
								Jeep Liberty, Toyota RAV4, Honda CR-V, BMW X6, Volkswagen
								Touareg, Range Rover
							</p>
							<div className='card__bot'>
								<div className='card__bot-part'>
									<p className='card__bot-text'>8pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 400) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 1000) / 100) * 100}
									</p>
								</div>
								<div className='card__bot-part'>
									<p className='card__bot-text'>16pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 600) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 1200) / 100) * 100}
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='card'>
							<div className='card__img-box'>
								<img src={multiPurpose} alt='car' />
							</div>
							<div className='card__title-color'>
								{t('multiPurpose')}
								<span className='card__title'>m-class</span>
							</div>
							<p className='card__text'>
								Skoda Yetti, Fiat Doblo, Renault Kangoo
							</p>
							<div className='card__bot'>
								<div className='card__bot-part'>
									<p className='card__bot-text'>8pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 400) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 600) / 100) * 100}
									</p>
								</div>
								<div className='card__bot-part'>
									<p className='card__bot-text'>16pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 650) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 800) / 100) * 100}
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className='card'>
							<div className='card__img-box'>
								<img src={coupeCars} alt='car' />
							</div>
							<div className='card__title-color'>
								{t('sportCoupes')}
								<span className='card__title'>s-class</span>
							</div>
							<p className='card__text'>
								Mercedes-Benz AMG GT, Hyundai Coupe, Subaru BRZ, Audi A5, BMW M4
							</p>
							<div className='card__bot'>
								<div className='card__bot-part'>
									<p className='card__bot-text'>8pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 700) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 1500) / 100) * 100}
									</p>
								</div>
								<div className='card__bot-part'>
									<p className='card__bot-text'>16pin</p>
									<p className='card__bot-price'>
										&#8372;{Math.ceil((conversionMoney * 1500) / 100) * 100} -
										&#8372;
										{Math.ceil((conversionMoney * 2000) / 100) * 100}
									</p>
								</div>
							</div>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
		</section>
	);
};
